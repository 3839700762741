<template>
  <div>
    <PickupProcessing
      v-if="order && order.id"
      :order="order"
      @onFinished="getProcessing"
    />
    <PickupWaitList v-else @onStarted="getProcessing" />
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Pickup",
  components: {
    PickupWaitList: () => import("@/components/dc/PickupWaitList"),
    PickupProcessing: () => import("@/components/dc/PickupProcessing"),
  },
  data: () => ({
    order: {},
  }),
  mounted() {
    this.getProcessing();
  },
  methods: {
    getProcessing() {
      httpClient.post("/dc/v1/pickup-get-order-processing").then(({ data }) => {
        this.order = { ...data };
      });
    },
  },
};
</script>
